import * as React from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import moment from "moment";

const BlogPostTemplate = ({ data: { previous, next, site, markdownRemark: post } }: any) => {
    const siteTitle = site.siteMetadata?.title || `Title`;

    const htmlWithIDs = post.html;
    // .replace(/<h2/g, '<h2 id="').replace(/<\/h2>/g, '"></h2>');

    const featuredImg = getImage(post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData);

    return (
        <Layout pageTitle={siteTitle} activeKey={"/blog/"}>
            <article className="blog-post" itemScope itemType="https://schema.org/Article">
                <header>
                    <h1 itemProp="headline">{post.frontmatter.h1}</h1>
                    <div>
                        {(post.frontmatter.tags || []).map((tag: string) => (
                            <span key={tag}><a href={"/tag/" + (tag.replace(/ /g, "-").toLowerCase())+"/"}>{tag}</a>{" "}</span>
                        ))}
                    </div>
                    <p
                        itemProp={"datePublished"}
                        content={moment(post.frontmatter.date, "MMMM DD, YYYY").format()}
                    >
                        {post.frontmatter.date}
                    </p>
                    {featuredImg ? (
                        <GatsbyImage
                            image={featuredImg}
                            alt={post.frontmatter.title}
                            loading={"eager"}
                            itemProp={"image"}
                        />
                    ) : null}
                </header>
                {/*<section dangerouslySetInnerHTML={{ __html: post.tableOfContents }} />*/}
                <section dangerouslySetInnerHTML={{ __html: htmlWithIDs }} itemProp="articleBody" />
                <hr />
                <footer>
                    <Bio />
                </footer>
            </article>
            <nav className="blog-post-nav">
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={"/blog" + previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={"/blog" + next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </Layout>
    );
};

export const Head = ({ data: { markdownRemark: post } }: any) => {
    return (
        <Seo
            title={post.frontmatter.title}
            shortTitle
            description={post.frontmatter.description || post.excerpt}
            url={"/blog" + post.fields.slug}
        />
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                h1
                title
                tags
                date(formatString: "MMMM DD, YYYY")
                description
                featuredImage {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            fields {
                slug
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`;
